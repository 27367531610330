import { SupremoFeaturesList } from '@utils/types/store';
import { useTranslation } from 'react-i18next';
import BasicTable from '@components/BasicTable';
import { createColumnHelper } from '@tanstack/react-table';
import { renderSupremoFeaturesColumn } from '@components/SupremoAccessAndControlTable';

function SupremoSecurityAndPrivacyTable() {
  const { t } = useTranslation('supremoControl');

  const columnHelper = createColumnHelper<any>();

  const versionColumns = [
    columnHelper.accessor((row) => row.feature, {
      id: 'feature',
      cell: (info) => info.getValue(),
      header: () => <span></span>,
    }),
    columnHelper.accessor((row) => row.solo, {
      id: 'solo',
      cell: (info) => info.getValue(),
      header: () => <span>Solo</span>,
    }),
    columnHelper.accessor((row) => row.business, {
      id: 'business',
      cell: (info) => info.getValue(),
      header: () => <span>Business</span>,
    }),
    columnHelper.accessor((row) => row.professional, {
      id: 'professional',
      cell: (info) => info.getValue(),
      header: () => <span>Professional</span>,
    }),
  ];

  const featuresNameSecurityAndPrivacy: SupremoFeaturesList[] = [
    {
      name: t(
        'compareSubscription.table.securityAndPrivacy.twoFactorAuthentication.title'
      ),
      tooltip: t(
        'compareSubscription.table.securityAndPrivacy.twoFactorAuthentication.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t(
        'compareSubscription.table.securityAndPrivacy.accessPasswordCustomization.title'
      ),
      tooltip: t(
        'compareSubscription.table.securityAndPrivacy.accessPasswordCustomization.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
    {
      name: t(
        'compareSubscription.table.securityAndPrivacy.accessPolicySetting.title'
      ),
      tooltip: t(
        'compareSubscription.table.securityAndPrivacy.accessPolicySetting.tooltip'
      ),
      solo: true,
      business: true,
      pro: true,
    },
  ];

  const featuresSecurityAndPrivacy = [
    ...renderSupremoFeaturesColumn(featuresNameSecurityAndPrivacy),
  ];

  return (
    <BasicTable
      isHeaderHidden
      isTitleRow
      data={featuresSecurityAndPrivacy}
      columns={versionColumns}
    />
  );
}

export default SupremoSecurityAndPrivacyTable;
